@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,700italic,900,900italic,500italic,500,400italic,300italic,300,100italic,100&subset=latin,cyrillic);
article,
aside,
audio,
canvas,
command,
datalist,
details,
embed,
figcaption,
figure,
footer,
header,
hgroup,
keygen,
meter,
nav,
output,
progress,
section,
source,
video {
    display: block;
}
mark,
rp,
rt,
ruby,
summary,
time {
    display: inline;
}
/* Global properties ======================================================== */
html {
    width: 100%;
}

body {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-size: 17px;
    color: #4b6570;
    min-width: 320px;
    background: #fff;
    -webkit-text-size-adjust: none;
}
.main {
    max-width: 960px;
    margin: 0 auto;
}

.wide-main {
    max-width: 1280px;
    margin: 0 auto;
}

.main-r {
    max-width: 960px;
    margin: auto;
}

.b-account {
    padding-top: 53px;
    float: right;
    font-size: 19px;
}

.enter {
    color: #4b6670;
}

.registration {
    color: #4b6670;
}

.registration a {
    color: #4b6670;
    border: 2px solid #e83d85;
    border-radius: 5px;
    padding: 5px 25px;
}

.registration a:hover {
    color: #fff;
    background: #e83d85;
}

.b-account-url {
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
/*----------------------> Links And buttons <------------------*/
a {
    outline: none;
    text-decoration: none;
    cursor: pointer;
    color: #e83d85;
}
a:hover {
    text-decoration: none;
}
button {
    display: inline-block;
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    border: none;
    outline: none;
    cursor: pointer;
}

/*----------------------> Basic Classes <------------------*/
.reg {
    text-transform: uppercase;
}
.f_left {
    float: left;
}
.f_right {
    float: right;
}
.al_left {
    text-align: left;
}
.al_right {
    text-align: right;
}
.al_center {
    text-align: center;
}

.it {
    font-style: italic;
}
.let {
    letter-spacing: -1px;
}
.bottom60 {
    margin-bottom: 60px;
}

.full_w {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.val_mid {
    vertical-align: middle;
}
/*----------------------> Indents Zero <------------------*/

.m_b_zero {
    margin-bottom: 0 !important;
}
.m_l_zero {
    margin-left: 0 !important;
}
.p_b_zero {
    padding-bottom: 0 !important;
}
.p_l_zero {
    padding-left: 0 !important;
}
.p_t_zero {
    padding-top: 0 !important;
}

/*----------------------> Positions <------------------*/
.p_abs {
    position: absolute;
}
.p_rel {
    position: relative;
}
.p_fix {
    position: fixed;
}
.clear {
    clear: both;
    width: 100%;
    line-height: 0;
    font-size: 0;
}
.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}
.wrapper {
    overflow: hidden;
}
.f_elem > *:first-child {
    margin-top: 0 !important;
}
.f_elem1 > *:first-child {
    margin-top: 0 !important;
    border: none !important;
}

.bg_none {
    background: none !important;
}

/*----------------------> Displays <------------------*/

.d_block {
    display: block;
}
.d_ib {
    display: inline-block;
}
.d_none {
    display: none;
}
.d_tc {
    display: table-cell;
}
.d_t {
    display: table;
}

/*----------------------> Basic Colors <------------------*/
.white {
    color: #fff;
}
.pink {
    color: #e83d85;
}
.gray {
    color: #7d9199;
}
.orange {
    color: #f7941d;
}

/*======================> Header <======================*/

.developer {
    display: inline-block;
    margin: 35px 0px 0px 0px;
}
.developer a {
    display: block;
    width: 126px;
    height: 155px;
    background: url(../images/developer.png) 0 0 no-repeat;
    text-decoration: none;
    text-indent: -9999px;
    background-size: contain;
}
.header_text {
    text-align: center;
}
.header_text p {
}
.header_text p.fz35 sup {
    line-height: 0.5;
}
/*=====================> Content ========================*/
#content {
    padding: 10px 0px 0px;
}
.steps_main_box {
    min-height: 434px;
    padding: 54px 0 30px 0;
}
.steps_container {
    text-align: center;
    margin-top: 30px;
    position: relative;
}
.steps_container_item {
    display: inline-block;
    width: 26%;
}
.steps_container .steps_container_item:nth-child(2),
.steps_container .steps_container_item:nth-child(4) {
    position: absolute;
    top: 0;
    padding: 60px 0px 0px 0px;
    width: 162px;
    -webkit-transform: translateX(-24%);
    -moz-transform: translateX(-24%);
    -ms-transform: translateX(-24%);
    -o-transform: translateX(-24%);
    transform: translateX(-24%);
}
.steps_container .steps_container_item:nth-child(4) {
    -webkit-transform: translateX(-76%);
    -moz-transform: translateX(-76%);
    -ms-transform: translateX(-76%);
    -o-transform: translateX(-76%);
    transform: translateX(-76%);
}
.steps_container .steps_container_item:nth-child(3) {
    margin: 0 9%;
}
.steps_container_item > p {
}
.steps_container .steps_container_item:nth-child(1) .steps_container_img,
.steps_container .steps_container_item:nth-child(3) .steps_container_img,
.steps_container .steps_container_item:nth-child(5) .steps_container_img {
    background: url(../images/step_img1.png) 0 0 no-repeat;
    width: 125px;
    height: 137px;
    display: inline-block;
}
.steps_container .steps_container_item:nth-child(3) .steps_container_img {
    background: url(../images/step_img2.png) 0 0 no-repeat;
}
.steps_container .steps_container_item:nth-child(5) .steps_container_img {
    background: url(../images/step_img3.png) 0 0 no-repeat;
}
.steps_container_img {
    color: #fff;
    text-align: center;
    line-height: 136px;
    font-size: 47px;
    margin-bottom: 35px;
}

.question_main_box {
    min-height: 301px;
    background-color: #f9f9f9;
    text-align: center;
    padding: 60px 0 30px 0;
}

.system_tracking_main_box {
    min-height: 380px;
    background-color: #f9f9f9;
    text-align: center;
    padding: 60px 0 30px 0;
}
.partners_main_box {
    min-height: 380px;
    background-color: #f9f9f9;
    text-align: center;
    padding: 60px 0 30px 0;
}
.question_container {
    text-align: center;
    margin-top: 55px;
}
.tel {
    font-size: 30px;
    color: #303c40;
    padding: 0 0 0 50px;
    background: url(../images/tel_img.png) 0 50% no-repeat;
    display: inline-block;
    margin-right: 86px;
}
.mail {
    font-size: 30px;
    color: #e83d85;
    background: url(../images/mail_img.png) 0 50% no-repeat;
    display: inline-block;
    padding: 0 0 0 58px;
}

.what_this_box {
    min-height: 378px;
    background: url(../images/bg2.png) 50% 0% no-repeat;
    padding: 25px 0 30px 0;
}
.what_this_box .title {
    color: #ffc600;
    margin-bottom: 33px;
}
.what_container {
    padding: 0 42px;
}
.what_container p {
    font-size: 20px;
    color: #fff;
    margin-bottom: 25px;
}

.system_tracking_main_box {
}
.system_tracking_main_box .title {
    max-width: 690px;
    margin: auto;
}
.system_tracking_container {
    display: table;
    margin: 0 auto;
    margin-top: 63px;
}
.system_tracking_container_li {
    display: table-cell;
    vertical-align: middle;
    padding: 0 95px 0 10px;
}
.system_tracking_container_li a {
    display: inline-block;
}
.system_tracking_container_li:last-child {
    padding: 0 10px;
}
.system_tracking_container_li img {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}

.partners_container {
    display: flex;
    margin: 20px 0;
    justify-content: space-around;
    align-items: center;
}

.partner_container_li {
    flex: 1;
    max-width: 15%;
}

.partner_container_li--teledirekt {
    max-width: 24%;
}
.partner_container_li--people {
    max-width: 15%;
    text-align: center;
}

.partner_container_li img {
    width: 100%;
}

.social_container {
    max-width: 690px;
    margin: auto;
    display: table;
    margin-top: 30px;
    margin-bottom: 30px;
}
.social_container_li {
    display: table-cell;
    vertical-align: middle;
    padding: 0 40px 0 10px;
}
/* .social_container_li a{
          #display: inline-block;
        }
      .social_container_li:last-child{
        #padding: 0 10px;
      } */
.social_container_li img {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}

/*=====================> form ========================*/

.login-form {
    width: 350px;
}

.form {
    margin: auto;
}
.auth-form {
}
.form.login {
    height: 325px;
}
.form.change-password {
    height: 350px;
}
.form.recover {
    height: 260px;
}
.w2ui-form-header small {
    /*color: #808080;*/
}
.w2ui-field {
    overflow: hidden;
}
.w2ui-field div {
    float: left;
    margin-left: 0;
}

#errors-block {
    color: #e65757;
}
#message-block {
    text-align: center;
}
#red {
    color: #e65757;
}
#green {
    color: green;
}
.w2ui-field > label {
    padding-left: 5px;
    margin: 0;
    color: white;
    width: auto;
    min-height: 15px;
}
.register .w2ui-field > label {
}

.login-page__header {
    text-align: center;
}
.login-page__header h1 {
    font-weight: normal;
    color: #00bec0;
}
.login-page__bullets {
    width: 530px;
    margin: 10px auto 30px;
}
.login-page__bullets ul {
    list-style-position: inside;
}
.login-page__bullets li {
    font-size: 0.9em;
    line-height: 1.5;
    list-style-image: url('../images/check.png');
}
.login-page__form {
    color: white;
    position: absolute;
    z-index: 2;
}

.login-page__form .w2ui-form {
    border: 0;
}

.page__form .form {
    background: #34495e;
    color: white;
}

.page__form {
    display: none;
}

.w2ui-form .w2ui-form-header {
    border-bottom: 1px solid #34495e !important;
    background: none;
    color: white;
    font-size: 3em;
    padding: 15px;
    position: relative;
}

.w2ui-form .w2ui-buttons {
    background: none;
    border-top: 0;
}

.w2ui-buttons button {
    background: #e65757;
    border: 0;
    box-shadow: none;
    padding: 15px 20px !important;
    width: 100%;
    color: white;
    font-size: 2em;
    border-radius: 4px;
    cursor: pointer;
}

.w2ui-reset {
    font-family: inherit;
}

.w2ui-page {
    width: 90%;
    margin: auto;
    position: static;
    text-align: left;
}

.w2ui-page input {
    width: 100%;
    padding: 10px;
}

.w2ui-field div {
    float: none;
}

.w2ui-form .w2ui-page {
    position: static;
    overflow: visible;
}

.w2ui-form .w2ui-buttons {
    position: static;
}
.w2ui-form .w2ui-form-header {
    padding-bottom: 10px;
}
.login-form__message-block {
    min-height: 22px;
}

.w2ui-form .w2ui-buttons {
    padding: 0 10px !important;
    width: 90%;
    margin: auto;
}

.dop-links {
    margin: 15px 0;
}

.dop-links a {
    color: #d2d2d2;
    font-size: 1.2em;
    text-decoration: none;
}

.dop-links a:hover {
    text-decoration: underline;
}

.w2ui-form .w2ui-buttons input[type='button'],
.w2ui-form .w2ui-buttons button {
    margin-right: 0;
}

.help-block {
    padding-left: 10px;
    color: #ccc;
}

.register-page .w2ui-field,
.change-password-page .w2ui-field {
    margin-bottom: 20px;
}

.register-page .w2ui-form-header {
    margin-bottom: 40px;
}

.register-page #message-block {
    top: 52px;
    left: 210px;
}

.recover-page #message-block {
    top: 52px;
    left: 5%;
    width: 90%;
}

.change-password-page #message-block {
    top: 52px;
    left: 5%;
    width: 90%;
}

.w2ui-field.w2ui-required > div::before {
    position: static;
    margin-left: 5px;
    font-size: 1.5em;
    color: #e65757;
}

/*.guiders_arrow_up {
    display: block;
    background-position: 0px -126px;
    top: -42px;
}*/
.guiders_arrow {
    width: 46px;
    height: 23px;
    position: relative;
    background-repeat: no-repeat;
    z-index: 100000006 !important;
    background-image: url('img/form-arrow.png');
    left: 145px;
}

/* block */

.steps_advantage {
    float: left;
    width: 460px;
    margin-top: 30px;
}

.steps_advantage img {
    float: left;
    width: 125px;
    margin: 0px 30px 30px 0px;
}

.steps_advantage p {
    color: #303c40;
    margin: 30px 23px 0px 0px;
    text-align: left;
}

.social_container {
    margin: 4px auto;
    display: block;
}

.social_container_li {
    display: table-cell;
    vertical-align: middle;
    padding: 0px 0px 0px 0px;
    display: block;
}

.social_container_li img {
    vertical-align: middle;
    height: auto;
    margin: 10px 15px 0px 0px;
}

.right-col {
    margin-left: 40px;
}

/*=====================> Paragraphs ========================*/

p {
    margin-bottom: 18px;
}
.mb1 {
    margin-bottom: 1px;
}
.mb2 {
    margin-bottom: 2px;
}
.mb3 {
    margin-bottom: 3px;
}
.mb4 {
    margin-bottom: 4px;
}
.mb5 {
    margin-bottom: 5px;
}
.mb6 {
    margin-bottom: 6px;
}
.mb7 {
    margin-bottom: 7px;
}
.mb8 {
    margin-bottom: 8px;
}
.mb9 {
    margin-bottom: 9px;
}
.mb10 {
    margin-bottom: 10px;
}
.mb11 {
    margin-bottom: 11px;
}
.mb12 {
    margin-bottom: 12px;
}
.mb13 {
    margin-bottom: 13px;
}
.mb14 {
    margin-bottom: 14px;
}
.mb15 {
    margin-bottom: 15px;
}
.mb16 {
    margin-bottom: 16px;
}
.mb17 {
    margin-bottom: 17px;
}
.mb18 {
    margin-bottom: 18px;
}
.mb19 {
    margin-bottom: 19px;
}
.mb20 {
    margin-bottom: 20px;
}
.mb21 {
    margin-bottom: 21px;
}
.mb22 {
    margin-bottom: 22px;
}
.mb23 {
    margin-bottom: 23px;
}
.mb24 {
    margin-bottom: 24px;
}
.mb25 {
    margin-bottom: 25px;
}
.mb26 {
    margin-bottom: 26px;
}
.mb27 {
    margin-bottom: 27px;
}
.mb28 {
    margin-bottom: 28px;
}
.mb29 {
    margin-bottom: 29px;
}
.mb30 {
    margin-bottom: 30px;
}
.mb31 {
    margin-bottom: 31px;
}
.mb32 {
    margin-bottom: 32px;
}
.mb33 {
    margin-bottom: 33px;
}
.mb34 {
    margin-bottom: 34px;
}
.mb35 {
    margin-bottom: 35px;
}
.mb36 {
    margin-bottom: 36px;
}
.mb37 {
    margin-bottom: 37px;
}
.mb38 {
    margin-bottom: 38px;
}
.mb39 {
    margin-bottom: 39px;
}
.mb40 {
    margin-bottom: 40px;
}
.mb41 {
    margin-bottom: 41px;
}
.mb42 {
    margin-bottom: 42px;
}
.mb43 {
    margin-bottom: 43px;
}
.mb44 {
    margin-bottom: 44px;
}
.mb45 {
    margin-bottom: 45px;
}
.mb46 {
    margin-bottom: 46px;
}
.mb47 {
    margin-bottom: 47px;
}
.mb48 {
    margin-bottom: 48px;
}
.mb49 {
    margin-bottom: 49px;
}
.mb50 {
    margin-bottom: 50px;
}
.mb51 {
    margin-bottom: 51px;
}
.mb52 {
    margin-bottom: 52px;
}
.mb53 {
    margin-bottom: 53px;
}
.mb54 {
    margin-bottom: 54px;
}
.mb55 {
    margin-bottom: 55px;
}
.mb58 {
    margin-bottom: 58px;
}
.mb62 {
    margin-bottom: 62px;
}
.mb75 {
    margin-bottom: 75px;
}

/*=====================> Titles ========================*/
h2,
h3,
h4 {
    font-weight: normal;
}
.title {
    font-size: 37px;
}
.title1 {
    font-size: 30px;
}

.fz12 {
    font-size: 12px;
    line-height: 1.2;
}
.fz35 {
    font-size: 35px;
    line-height: 1.23;
}
.fz75 {
    font-size: 75px;
    line-height: 1.23;
}

/*================================>> GOOGLE FONTS  <<========================================*/
[class*='roboto_'] {
    font-family: 'Roboto', sans-serif;
}
.roboto_100 {
    font-weight: 100;
}
.roboto_300 {
    font-weight: 300;
}
.roboto_400 {
    font-weight: 400;
}
.roboto_400 a:hover {
    text-decoration: underline;
}
.roboto_500 {
    font-weight: 500;
}
.roboto_700 {
    font-weight: 700;
}
.roboto_900 {
    font-weight: 900;
}

.td_und {
    text-decoration: underline;
}
.td_und:hover {
    text-decoration: none;
}

.td_und_no {
    text-decoration: none;
}
.td_und_no:hover {
    text-decoration: underline;
}
/*================================>> hovers <<========================================*/
a {
    -webkit-transition: all 0.7s ease;
    -moz-transition: all 0.7s ease;
    -o-transition: all 0.7s ease;
    transition: all 0.7s ease;
}
a:hover {
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
}
/*================================>> Footer <<========================================*/
.try_btn {
    padding: 21.5px 130.5px;
    background-color: #e83d85;
    font-size: 30px;
    line-height: 35px;
    color: #fff;
    display: inline-block;
}
.try_btn:hover {
    background-color: #f05596;
}

#slidebox {
    width: 100%;
    height: 212px;
    background: url(../images/bg2.jpg) 50% 0% no-repeat;
    min-height: 212px;
    text-align: center;
    padding: 48px 0px;
    z-index: 999;
    position: fixed;
    top: 0px;
}

hr {
    display: none;
}

/*==================================RESPONSIVE LAYOUTS END===============================================*/

.notifyjs-wrapper .notifyjs-bootstrap-success {
    background-color: #c2d5ed;
    border: none;
    color: #444;
}

.notifyjs-wrapper .notifyjs-bootstrap-base {
    border: 0;
    font-weight: normal;
}

/*=========================FLEX SLIDE ==================================================================*/
.cd-testimonials-wrapper {
    max-width: 960px;
    margin: 0 auto;
    position: relative;
}

.slider-item {
    width: 90%;
}

.flex-direction-nav li {
    position: absolute;
    height: 100%;
    width: 40px;
    top: 0;
}

.flex-direction-nav li:first-child {
    left: 0;
}

.flex-direction-nav li:last-child {
    right: 0;
}

.flex-direction-nav li a {
    display: block;
    height: 100%;
    width: 100%;

    /* image replacement */
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;

    transition: background-color 0.2s;
}

.flex-direction-nav li a::before,
.flex-direction-nav li a::after {
    /* левая и правая стрелки на чистом CSS */
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 2px;
    height: 13px;
    background-color: black;
}

.flex-direction-nav li a::before {
    transform: translateY(-35px) rotate(45deg);
}

.flex-direction-nav li a::after {
    transform: translateY(-27px) rotate(-45deg);
}

.flex-direction-nav li:last-child a::before {
    transform: translateY(-35px) rotate(-45deg);
}

.flex-direction-nav li:last-child a::after {
    transform: translateY(-27px) rotate(45deg);
}

.slide-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 380px;
    width: 90%;
    margin: auto;
    text-align: left;
}

.slide-author {
    flex: 1;
}

.slide-author__job {
    line-height: 15px;
}

.slide-author img {
    width: 90%;
    margin-bottom: 10px;
    border-radius: 50%;
}
.slide-author small {
    font-size: 0.8em;
    line-height: 15px;
}

.slide-review {
    flex: 3;
    max-width: 90%;
}

.slide-review h3 {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 1.3em;
}

.reviews_slider_main_box {
    background: white;
    position: relative;
}

.social-links-container {
    margin-top: 15px;
}

.social-links-container a {
    display: inline-block;
    vertical-align: middle;
}
.social-links-container a img {
    width: 64px;
    height: 64px;
}
.social-links-container a + a {
    margin-left: 15px;
}

.social-links-container .social-link--unknown-social-network img {
    width: 42px;
    height: 42px;
}

.try-button-block {
    margin-bottom: 25px;
}
