/*==================================RESPONSIVE LAYOUTS===============================================*/
@media only screen and (max-width: 1250px) {
    .system_tracking_main_box {
        padding: 0px 0px 0px;
    }

    @media only screen and (max-width: 1024px) {
        .system_tracking_main_box {
            padding: 77px 0px 0px;
        }
    }

    @media only screen and (max-width: 995px) {
        .system_tracking_main_box {
            padding-bottom: 150px;
        }

        #slidebox {
            width: 100%;
            height: 106px;
            background: url(../images/bg3.jpg) 50% 0% no-repeat;
            min-height: 106px;
            text-align: center;
            padding: 48px 0px;
            z-index: 999;
            position: fixed;
            bottom: -105px;
            box-shadow: 0 0 10px #aaa;
            -moz-box-shadow: 0 0 10px #aaa;
            -webkit-box-shadow: 0 0 10px #aaa;
        }

        .try_btn {
            padding: 15.5px 10.5px;
            background-color: #e83d85;
            font-size: 30px;
            line-height: 20px;
            color: #FFF;
            display: inline-block;
        }

        footer {
            padding: 10px 0;
        }
    }
    @media only screen and (max-width: 787px) {
        .system_tracking_main_box {
            padding-bottom: 90px;
        }

        #slidebox {
            width: 100%;
            height: 106px;
            background: url(../images/bg3.jpg) 50% 0% no-repeat;
            min-height: 106px;
            text-align: center;
            padding: 48px 0px;
            z-index: 999;
            position: fixed;
            bottom: -105px;
            box-shadow: 0 0 10px #aaa;
            -moz-box-shadow: 0 0 10px #aaa;
            -webkit-box-shadow: 0 0 10px #aaa;
        }

        .try_btn {
            padding: 15.5px 10.5px;
            background-color: #e83d85;
            font-size: 30px;
            line-height: 20px;
            color: #FFF;
            display: inline-block;
        }

        footer {
            padding: 10px 0;
        }
    }

    .steps_advantage img {
        float: none;
        margin: 0 auto;
    }

    .steps_advantage p {
        text-align: center;
    }

    .right-col {
        margin-left: 0px;
    }

    .social_container_li img {
        float: left;
        margin: 5px;
    }

    .what_this_box {
        min-height: 278px;
    }

    .fz75, .title {
        font-size: 37px;
    }
}
    
    /*==================================RESPONSIVE LAYOUTS START===============================================*/
@media only screen and (max-width: 980px) {
  .main {
    max-width: 767px;
  }
  .logo{
    margin-bottom: 60px;
  }
    .developer{
    margin-bottom: 60px;
  }
  .steps_container .steps_container_item:nth-child(3) {
        margin: 0 3%;
  }
  .steps_container .steps_container_item:nth-child(2), 
  .steps_container .steps_container_item:nth-child(4){
    width: 65px; 
    overflow: hidden;
    -webkit-transform: translateX(-35%);
        -moz-transform: translateX(-35%);
        -ms-transform: translateX(-35%);
        -o-transform: translateX(-35%);
        transform: translateX(-35%);
  }
  .steps_container .steps_container_item:nth-child(4){
    -webkit-transform: translateX(-70%);
        -moz-transform: translateX(-70%);
        -ms-transform: translateX(-70%);
        -o-transform: translateX(-70%);
        transform: translateX(-70%);
  }
  .steps_container .steps_container_item:nth-child(2) img, 
  .steps_container .steps_container_item:nth-child(4) img{
    margin-left: -98px;
  }
  .steps_container_item{
    width: 30%;
  }
  .fz75{
    font-size: 60px;
  }
  header,
  .what_this_box{
    -webkit-background-size: cover;
         -o-background-size: cover;
            background-size: cover;
  }
  .what_container{
    padding: 0;
  }
  .system_tracking_container_li{
    padding: 0 6px 0 10px;
  }
}

@media only screen and (max-width: 787px) {
  .main ,
  .system_tracking_main_box .title{
    max-width: 479px;
  }
  .logo{
    margin-bottom: 30px;
      float: none;
  }
    
    .login-page__form {
    width: 100%;
}
    
    .guiders_arrow {
        display: none;
}
    
    .b-account {
    padding-top: 13px;
    float: none;
    display: block;
    margin-bottom: 30px;
}
    
    .enter a {
        margin-bottom: 20px;
    }
    
  .fz75 {
        font-size: 45px;
  }
  header{
    padding: 20px 0; 
    min-height: 448px;
  }
  .fz35 {
    font-size: 25px;
  }
  .fz75 ,
  .title{
    font-size: 30px;
  }
  .header_text p.mb40{
    margin-bottom: 18px;
  }
  .steps_main_box,
  .question_main_box, 
  .system_tracking_main_box,
  .what_this_box{
    padding: 20px 0;
  }
  .steps_container_item{
    width: 100%;
    display: block;
  }
  .steps_container .steps_container_item:nth-child(2), 
  .steps_container .steps_container_item:nth-child(4){
    display: none;
  }
  .steps_container .steps_container_item:nth-child(3){
    margin: 0;
  }
  .steps_container_img{
    margin-bottom: 15px;
  }
  .steps_container_item > p br{
    display: none;
  }
  .steps_container .steps_container_item:nth-child(1) .steps_container_img, 
  .steps_container .steps_container_item:nth-child(3) .steps_container_img, 
  .steps_container .steps_container_item:nth-child(5) .steps_container_img{
    width: 95px;
    height: 104px;
    -webkit-background-size: 100%;
         -o-background-size: 100%;
            background-size: 100%;
  }
  .steps_container_img{
    line-height: 104px;
  }
  .question_main_box, 
  .system_tracking_main_box{
    min-height: 250px;
  }
  .title1 {
        font-size: 24px;
  }
  .tel{
    margin-right: 0;
    margin-bottom: 15px;
  }
  .question_container{    
    margin-top: 35px;
  }
  .what_container p{
    font-size: 17px;
    margin-bottom: 18px;
  }
  .system_tracking_container{
    display: block;
    margin-top: 10px;
  }
  .system_tracking_container_li,
  .system_tracking_container_li:last-child{
    padding: 0;
    margin: 10px 0;
    width: 49%;
    display: inline-block;
  }
  .try_btn {
    padding: 21.5px 55px;
    font-size: 24px;
    line-height: 28px;
  }
  footer {
        padding: 35px 0;
  }
    
    #login-form {
    width: 350px;
}
    
}

@media only screen and (max-width: 505px) {
  .main ,
  .system_tracking_main_box .title{
    padding: 0 15px;
  }
  .steps_container{
    padding: 0 10px;
  }
  .steps_advantage {
    width: 100%;
}
}

@media only screen and (max-width: 400px) {
  .system_tracking_container_li, 
  .system_tracking_container_li:last-child{
    width: 100%;
  }
  .tel,
  .mail{
    font-size: 24px;
    padding: 5px 0 5px 58px;
  }
  .try_btn{
    padding: 21.5px 30px;
    font-size: 20px;
    line-height: 24px;
  }
  .fz35{
    font-size: 20px;
  }
  .fz75, .title{
    font-size: 25px;
  }
  header{
    min-height: 405px;
  }
    
    hr {
        display:block;
        opacity: 0;
    }
    
    #login-form {
    width: 350px;
}
}
	
@media only screen and (max-width: 320px) {
 /*body{min-width:300px;}*/
.system_tracking_main_box 
	{
    padding-bottom: 150px;
}
	#slidebox{
	width: 100%;
	height: 106px;
	background: url(../images/bg3.jpg) 50% 0% no-repeat;
    min-height: 106px;
    text-align: center;
    padding: 48px 0px;
	z-index: 999;
	position: fixed;
	bottom: -105px;
	box-shadow: 0 0 10px #aaa;
	-moz-box-shadow: 0 0 10px #aaa;
	-webkit-box-shadow: 0 0 10px #aaa;
	}
	.try_btn{
    padding: 15.5px 10.5px;
    background-color: #e83d85;
    font-size: 30px;
    line-height: 30px;
    color: #FFF;
    display: inline-block;
  }
	footer {
        padding: 10px 0;
  }
    
    #login-form {
    width: 350px;
}

	}
