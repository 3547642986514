@font-face {
    font-family: Proxima;
    src: url('../Proxima/ProximaNova-Regular.ttf');
}
@font-face {
    font-family: Proxima;
    src: url('../Proxima/ProximaNova-Bold.ttf');
    font-weight: bold;
}
@font-face {
    font-family: Proxima;
    src: url('../Proxima/ProximaNova-Light.ttf');
    font-weight: 200;
}
@font-face {
    font-family: Proxima;
    src: url('../Proxima/ProximaNovaT-Thin.ttf');
    font-weight: 100;
}
@font-face {
    font-family: Proxima;
    src: url('../Proxima/ProximaNova-LightIt.ttf');
    font-weight: 200;
    font-style: italic;
}
.outer-container {
    width: 100%;
    max-width: 1720px;
    margin: 0 auto;
    display: flex;
    padding: 0 20px;
    box-sizing: border-box;
}
.inner-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
}
.core-content {
    display: flex;
    flex-direction: row;
    justify-items: stretch;
}
.button {
    font-size: 14px;
    height: 45px;
    padding: 0 35px;
    border-radius: 5px;
    border: none;
    background-color: #e83d85;
    color: #fff;
    cursor: pointer;
}
body {
    font-family: Proxima;
    margin: 0;
}
header .core-content {
    min-height: 135px;
}
header .inner-container {
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-width: 900px) {
    header .inner-container {
        flex-direction: column;
    }
}
.logo {
    height: 90px;
}
header .nav > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
}
@media screen and (max-width: 500px) {
    header .nav > ul {
        flex-direction: column;
    }
}
header .nav > ul li {
    display: inline-block;
}
header .nav > ul li > a {
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    color: #464f50;
    padding: 10px 15px;
    font-size: 13px;
}
header .login {
    display: flex;
    width: 340px;
    justify-content: flex-end;
}
@media screen and (max-width: 500px) {
    header .login {
        flex-direction: column;
        width: unset;
    }
}
header .login .register,
header .login .login-enter-link {
    display: block;
    text-decoration: none;
    color: #464f50;
    padding: 10px 15px;
    font-family: Proxima;
    font-size: 16px;
}
header .login .login-enter-link {
    margin-left: 130px;
    margin-right: 20px;
}
@media screen and (max-width: 500px) {
    header .login .login-enter-link {
        margin-left: 0;
        margin-right: 0;
    }

    header .login .register,
    header .login .login-enter-link {
        padding: 15px 0;
    }
}
@media screen and (max-width: 900px) {
    .login-page__form {
        left: 0;
    }
}
header .login .register {
    box-sizing: border-box;
    padding: 7px 20px;
    border: 2px solid #e83d85;
    border-radius: 5px;
}
.main-description {
    min-height: 700px;
    background-color: #f3f7f9;
}
.main-description .outer-container {
    overflow: hidden;
}
.main-description .outer-container .inner-container {
    position: relative;
}
.main-description .outer-container .inner-container .description {
    flex: 0 1 650px;
    display: flex;
    flex-direction: column;
    justify-content: start;
}
.main-description .outer-container .inner-container .description h1 {
    margin-top: 110px;
    margin-bottom: 0;
    font-size: 49px;
    font-weight: bold;
}
@media screen and (max-width: 500px) {
    .main-description .outer-container .inner-container .description h1 {
        font-size: 35px;
    }
}
.main-description .outer-container .inner-container .description p {
    max-width: 450px;
    margin-top: 40px;
    margin-bottom: 0;
    font-size: 19px;
}
.main-description .outer-container .inner-container .description form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 100px;
}
@media screen and (max-width: 600px) {
    .main-description .outer-container .inner-container .description form {
        flex-direction: column;
    }
}
.main-description .outer-container .inner-container .description form input[type='text'] {
    box-sizing: border-box;
    margin-right: 20px;
    border: 1px solid #aeb4b5;
    border-radius: 3px;
    outline: none;
    height: 45px;
    width: 310px;
    background-color: #f3f7f9;
    font-size: 16px;
    padding: 0 10px;
}
.main-description .outer-container .inner-container .description form button {
    font-size: 16px;
}
@media screen and (max-width: 600px) {
    .main-description .outer-container .inner-container .description form button {
        margin-top: 10px;
    }
}
.main-description .outer-container .inner-container .computer-guy {
    width: 50vw;
    height: 100%;
    position: absolute;
    left: 650px;
    top: 0;
    background-image: url('../images/computer-guy.png');
    background-repeat: no-repeat;
    background-position: 30px -130px;
}
.advertising-systems {
    min-height: 545px;
}
.advertising-systems .inner-container {
    justify-content: center;
}
.advertising-systems .inner-container .content {
    display: flex;
    max-width: 875px;
    flex-direction: column;
    align-items: center;
    margin-top: 105px;
}
.advertising-systems .inner-container .content h2 {
    font-size: 40px;
    margin: 0;
    text-align: center;
    font-weight: bold;
}
.advertising-systems .inner-container .content .description-container {
    margin-top: 30px;
    margin-bottom: 0;
    display: inline-block;
    text-align: center;
    max-width: 670px;
}
.advertising-systems .inner-container .content .description-container p {
    margin: 0;
    font-size: 19px;
}
.advertising-systems .inner-container .content .networks {
    list-style-type: none;
    margin-top: 55px;
    margin-bottom: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    max-width: 650px;
}
@media screen and (max-width: 430px) {
    .advertising-systems .inner-container .content .networks {
        justify-content: space-around;
    }
}
.advertising-systems .inner-container .content .networks > li {
    display: inline-block;
}
.advertising-systems .inner-container .content .networks > li svg, .advertising-systems .inner-container .content .networks > li img {
    width: 130px;
    height: 139px;
}
.advertising-systems .inner-container .content .networks > li svg .icon-background {
    fill: #fff;
}
.advertising-systems .inner-container .content .networks > li.vkads .icon-background {
    stroke: #2577f950;
    stroke-width: 2px;
}
.advertising-systems .inner-container .content .networks > li.vkads .icon-logo {
    fill: #2577f950;
}
.advertising-systems .inner-container .content .networks > li.vkads:hover .icon-background {
    fill: #2577f950;
}
.advertising-systems .inner-container .content .networks > li.vkads:hover .icon-logo {
    fill: #fff;
}
.advertising-systems .inner-container .content .networks > li.mt .icon-background {
    stroke: #ff2d39;
    stroke-width: 2px;
}
.advertising-systems .inner-container .content .networks > li.mt .icon-logo {
    fill: #ff2d39;
}
.advertising-systems .inner-container .content .networks > li.mt:hover .icon-background {
    fill: #ff2d39;
}
.advertising-systems .inner-container .content .networks > li.mt:hover .icon-logo {
    fill: #fff;
}
.advertising-systems .inner-container .content .networks > li.vk .icon-background {
    stroke: #758bc7;
    stroke-width: 2px;
}
.advertising-systems .inner-container .content .networks > li.vk .icon-logo {
    fill: #758bc7;
}
.advertising-systems .inner-container .content .networks > li.vk:hover .icon-background {
    fill: #758bc7;
}
.advertising-systems .inner-container .content .networks > li.vk:hover .icon-logo {
    fill: #fff;
}
.features {
    background-color: #f3f7f9;
    background-image: url('../images/bg-circles.svg'), url('../images/bg-circles.svg');
    background-position: 0 0, 100% 160%;
    background-repeat: no-repeat;
    min-height: 1110px;
}
.features .inner-container {
    justify-content: center;
}
.features .inner-container .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    box-sizing: border-box;
}
.features .inner-container .content h2 {
    font-size: 40px;
    margin: 0;
    text-align: center;
    font-weight: bold;
}
.features .inner-container .content .ad-management-instruments {
    margin-top: 25px;
    margin-bottom: 0;
    font-size: 19px;
    max-width: 480px;
    text-align: center;
}
.features .inner-container .content .features-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 65px;
    margin-left: -35px;
}
.features .inner-container .content .features-list > li {
    display: inline-block;
    background-color: #fff;
    width: 380px;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 35px;
    padding-bottom: 75px;
    box-sizing: border-box;
    margin-left: 30px;
    margin-top: 30px;
    border-radius: 5px;
}
.features .inner-container .content .features-list > li:hover {
    background-color: #e73c85;
    color: #fff;
    box-shadow: 0 0 0 10px #e73c85;
}
.features .inner-container .content .features-list > li:hover img {
    filter: brightness(0) invert(1);
}
.features .inner-container .content .features-list > li h3 {
    font-size: 23px;
    margin: 23px 0;
    font-weight: bold;
}
.features .inner-container .content .features-list > li p {
    font-size: 18px;
    line-height: 26px;
    font-weight: 200;
}
.features .inner-container .content .features-list img {
    height: 60px;
}
.vk-campaigns {
    min-height: 615px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.vk-campaigns .outer-container {
    padding-top: 115px;
    justify-content: space-around;
    align-items: center;
}
.vk-campaigns .outer-container .prev-slide,
.vk-campaigns .outer-container .next-slide {
    width: 22px;
    height: 40px;
    background-image: url('../images/arrow.png');
    cursor: pointer;
    margin: 0 15px;
    align-self: center;
}
@media screen and (max-width: 1360px) {
    .vk-campaigns .outer-container .prev-slide,
    .vk-campaigns .outer-container .next-slide {
        display: none;
    }
}
.vk-campaigns .outer-container .next-slide {
    transform: rotate(180deg);
    order: 1;
}
.vk-campaigns .outer-container .inner-container {
    margin: 0;
}
.vk-campaigns .outer-container .inner-container .content {
    width: 100%;
    overflow: hidden;
}
.vk-campaigns .outer-container .inner-container .content .slider-container {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    width: 300%;
}
.vk-campaigns .outer-container .inner-container .content .slider-container > li h2 {
    font-size: 40px;
    margin: 0;
    font-weight: bold;
}
.vk-campaigns ul {
    padding-left: 40px;
}
.vk-campaigns .outer-container .inner-container .content .slider-container > li p {
    font-size: 19px;
    margin-top: 15px;
    margin-bottom: 0;
}
.vk-campaigns .outer-container .inner-container .content .slider-container > li b {
    font-size: 19px;
}
.vk-campaigns .outer-container .inner-container .content .slide {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.vk-campaigns .outer-container .inner-container .content .slide svg {
    align-self: center;
    height: 260px;
    min-width: 25%;
}
@media screen and (max-width: 800px) {
    .vk-campaigns .outer-container .inner-container .content .slide svg, .vk-campaigns .outer-container .inner-container .content .slide img {
        display: none;
    }
}
.vk-campaigns .outer-container .inner-container .content .slide svg .icon-logo {
    fill: #fff;
}
.vk-campaigns .outer-container .inner-container .content .slide svg.vkads .icon-background {
    fill: #2577f9;
}
.vk-campaigns .outer-container .inner-container .content .slide svg.mt .icon-background {
    fill: #ff2d39;
}
.vk-campaigns .outer-container .inner-container .content .slide .unknown-social-network .icon-background {
    fill: #3c5a9a;
}
.vk-campaigns .outer-container .inner-container .content .slide .unknown-social-network {
    align-self: center;
    height: 260px;
    padding: 0 25px;
    box-sizing: content-box;
}
.vk-campaigns .outer-container .inner-container .content .slide svg.vk .icon-background {
    fill: #758bc7;
}
.vk-campaigns .outer-container .inner-container .content .slide-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.vk-campaigns .outer-container .inner-container .content .description {
    margin-top: 10px;
    list-style-type: none;
}
.vk-campaigns .outer-container .inner-container .content .description li {
    font-size: 19px;
    line-height: 39px;
    position: relative;
}
.vk-campaigns .outer-container .inner-container .content .description li:before {
    position: absolute;
    display: block;
    content: '';
    width: 21px;
    height: 17px;
    background-image: url('../images/checkmark.png');
    top: 10px;
    left: -35px;
}
.vk-campaigns .outer-container .inner-container .content .updates-list-container {
    margin-top: 70px;
}
.vk-campaigns .outer-container .inner-container .content .updates-list-container ul {
    margin-top: 10px;
    list-style-type: none;
}
.vk-campaigns .outer-container .inner-container .content .updates-list-container ul li {
    font-size: 19px;
    line-height: 39px;
    position: relative;
}
.vk-campaigns .outer-container .inner-container .content .updates-list-container ul li:before {
    filter: grayscale(100%);
    position: absolute;
    display: block;
    content: '';
    width: 21px;
    height: 17px;
    background-image: url('../images/checkmark.png');
    top: 10px;
    left: -35px;
}
.vk-campaigns .controls {
    display: flex;
    justify-content: space-between;
    width: 100px;
    margin-top: 80px;
    margin-bottom: 50px;
}
.vk-campaigns .controls .control {
    margin: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #bfcdd4;
    cursor: pointer;
}
.vk-campaigns .controls .checked {
    background-color: #17bcbd;
}
.for-agencies {
    background-color: #f3f7f9;
    min-height: 640px;
}
.for-agencies .inner-container {
    justify-content: flex-end;
}
.for-agencies .inner-container .content {
    position: relative;
}
.for-agencies .inner-container .content section {
    height: 100%;
    max-width: 640px;
}
.for-agencies .inner-container .content section h2 {
    margin-top: 0;
    padding-top: 110px;
    margin-bottom: 0;
    font-size: 40px;
    font-weight: bold;
}
.for-agencies .inner-container .content section p {
    margin-top: 40px;
    margin-bottom: 0;
    font-size: 20px;
    line-height: 34px;
    font-weight: 300;
    max-width: 600px;
}
.for-agencies .inner-container .content section .button {
    font-size: 16px;
    margin-top: 35px;
    margin-bottom: 35px;
}
.for-agencies .inner-container .content .agency-guys {
    position: absolute;
    width: 50vw;
    height: 100%;
    right: 640px;
    background-image: url('../images/agency-guys.png');
    background-repeat: no-repeat;
    background-position: right -150px;
}
.partners {
    min-height: 450px;
}
.partners .inner-container {
    justify-content: center;
    border-bottom: 1px solid #e5e8ea;
    max-width: 1400px;
}
.partners .inner-container .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.partners .inner-container .content h2 {
    margin-top: 80px;
    margin-bottom: 0;
    font-size: 40px;
    font-weight: bold;
}
.partners .inner-container .content p {
    margin-top: 25px;
    font-size: 19px;
    margin-bottom: 0;
}
.partners .inner-container .content .brands-list {
    margin-top: 100px;
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
}
.partners .inner-container .content .brands-list > li {
    display: inline-block;
}
.partners .inner-container .content .brands-list > li img {
    height: 100px;
    width: auto;
}
.reviews {
    min-height: 580px;
}
.reviews .outer-container {
    padding-top: 90px;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}
.reviews .outer-container .prev-slide,
.reviews .outer-container .next-slide {
    width: 22px;
    height: 40px;
    background-image: url('../images/arrow.png');
    cursor: pointer;
    margin: 0 15px;
}
@media screen and (max-width: 1360px) {
    .reviews .outer-container .prev-slide,
    .reviews .outer-container .next-slide {
        display: none;
    }
}
.reviews .outer-container .next-slide {
    transform: rotate(180deg);
    order: 1;
}
.reviews .outer-container .inner-container {
    max-width: 1200px;
    margin: 0;
}
.reviews .outer-container .inner-container .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}
.reviews .outer-container .inner-container .content h2 {
    font-size: 40px;
    margin: 0;
    font-weight: bold;
}
.reviews .outer-container .inner-container .content .slide {
    display: flex;
    width: 100%;
}
@media screen and (max-width: 800px) {
    .reviews .outer-container .inner-container .content .slide {
        display: initial;
    }
}
.reviews .outer-container .inner-container .content .slide article {
    max-width: 930px;
}
.reviews .outer-container .inner-container .content .avatar {
    position: relative;
    margin-right: 50px;
    margin-left: 5px;
}
@media screen and (max-width: 800px) {
    .reviews .outer-container .inner-container .content .avatar {
        float: left;
    }
}
.reviews .outer-container .inner-container .content .avatar:after {
    display: block;
    position: absolute;
    top: 0;
    right: -45px;
    width: 71px;
    height: 56px;
    content: '';
    background-image: url('../images/review-quotes.svg');
    font-size: 100px;
    font-family: Proxima;
}
.reviews .outer-container .inner-container .content .avatar img {
    border-radius: 50%;
    width: 170px;
    height: 170px;
    object-fit: cover;
}
.reviews .outer-container .inner-container .content .slider-container {
    list-style-type: none;
    margin-top: 70px;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    width: 1000%;
    align-self: flex-start;
}
.reviews .outer-container .inner-container .content .review-text {
    font-size: 19px;
    line-height: 29px;
    font-style: italic;
    margin: 0;
}
.reviews .outer-container .inner-container .content .author {
    margin-top: 40px;
    margin-bottom: 0;
}
.reviews .outer-container .inner-container .content .author .name {
    font-size: 19px;
    line-height: 34px;
    margin: 0;
    font-weight: bold;
}
.reviews .outer-container .inner-container .content .author .company {
    margin: 0;
    font-size: 17px;
    line-height: 34px;
}
.reviews .outer-container .inner-container .controls {
    display: flex;
    justify-content: space-between;
    width: 100px;
    margin-top: 80px;
    margin-bottom: 50px;
}
.reviews .outer-container .inner-container .controls .control {
    margin: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #bfcdd4;
    cursor: pointer;
}
.reviews .outer-container .inner-container .controls .checked {
    background-color: #17bcbd;
}
.try-it {
    background-color: #17bcbd;
    background-repeat: no-repeat;
    background-image: url('../images/circles.svg');
    background-position: 50% calc(50% + 25px);
    min-height: 455px;
}
.try-it .inner-container {
    justify-content: center;
    color: #fff;
}
.try-it .inner-container .content {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.try-it .inner-container .content h2 {
    font-size: 40px;
    margin-top: 90px;
    margin-bottom: 0;
    text-align: center;
    font-weight: bold;
}
.try-it .inner-container .content p {
    margin-top: 25px;
    margin-bottom: 0;
    font-size: 19px;
    text-align: center;
}
.try-it .inner-container .content form {
    margin-top: 70px;
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 600px) {
    .try-it .inner-container .content form {
        flex-direction: column;
    }
}
.try-it .inner-container .content form input[type='text'] {
    height: 50px;
    margin: 0;
    padding: 0 10px;
    border: none;
    border-radius: 5px;
    flex-grow: 1;
    font-size: 16px;
    align-self: stretch;
}
.try-it .inner-container .content form .button {
    height: 50px;
    width: 130px;
    margin-left: 10px;
    font-size: 16px;
}
@media screen and (max-width: 600px) {
    .try-it .inner-container .content form .button {
        margin: 15px 0;
        align-self: center;
    }
}
.footer-content {
    background-color: #50656e;
    min-height: 300px;
}
.footer-content .content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
    margin-top: 70px;
    color: #fff;
}
@media screen and (max-width: 900px) {
    .footer-content .content {
        justify-content: space-around;
    }
}
@media screen and (max-width: 530px) {
    .footer-content .content {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
}
.footer-content .content .rights {
    width: 120px;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
}
@media screen and (max-width: 750px) {
    .footer-content .content .rights {
        height: 60px;
    }
}
.footer-content .content .rights p {
    margin: 0;
    font-size: 14px;
}
.footer-content .content .offerta {
    margin-bottom: 10px;
}
.footer-content .content .offerta a {
    color: #fff;
    text-decoration: none;
}
.footer-content .content .offerta p {
    margin: 0;
    font-size: 14px;
}
.footer-content .content .support {
    width: 135px;
    margin-bottom: 10px;
}
.footer-content .content .support p {
    margin: 0;
    font-size: 14px;
}
.footer-content .content .support a {
    color: #fff;
    font-size: 17px;
    display: inline-block;
    margin-top: 20px;
    text-decoration: none;
}
@media screen and (max-width: 530px) {
    .footer-content .content .support a {
        margin: 0;
    }
}
.footer-content .content .social {
    width: 165px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}
.footer-content .content .social p {
    margin: 0;
    font-size: 14px;
}
.footer-content .content .social ul {
    list-style-type: none;
    width: 90px;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    padding: 0;
}
.footer-content .content .social ul > li {
    display: inline-block;
}
.footer-content .content .social ul > li img {
    width: 40px;
}
